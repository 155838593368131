<template>
  <b-modal
    id="assistant-madal"
    ref="assistant-madal"
    hide-footer
    :title="title"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="Username"
      >
        <b-input-group :prepend="userPrefix" class="mb-2 mr-sm-2 mb-sm-0">
          <b-form-input
            v-model="input.subUser"
            type="text"
            placeholder="รหัสผู้ใช้"
            :state="validate.username.valid"
            required
          ></b-form-input>
        </b-input-group>
        <b-form-invalid-feedback :state="validate.username.valid">{{validate.username.invalidText}}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label="Password"
      >
        <b-form-input
          v-model="input.password"
          type="password"
          placeholder="รหัสผ่าน"
          :required="!input._id"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="ชื่อ"
      >
        <b-form-input
          v-model="input.firstname"
          type="text"
          placeholder="ชื่อ"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="นามสกุล"
      >
        <b-form-input
          v-model="input.lastname"
          type="text"
          placeholder="นามสกุล"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="เบอร์โทร"
      >
        <b-form-input
          v-model="input.tel"
          type="text"
          placeholder="เบอร์โทร"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Line ID"
      >
        <b-form-input
          v-model="input.line_id"
          type="text"
          placeholder="Line ID"
        ></b-form-input>
      </b-form-group>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import AppConfig from '@/configs/app.config.js'

import AssistantService from '@/services/AssistantService.js'
import Swal from 'sweetalert2'

const defaultValidate = {
  username: {
    valid: null,
    invalidText: ''
  }
}

export default {
  name: 'AssistantModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    },
    title: {
      type: String
    }
  },
  data() {
    return {
      input: {},
      isProcess: false,
      needReload: false,
      validate: JSON.parse(JSON.stringify(defaultValidate))
    }
  },
  computed: {
    isBranch() {
      const LoggedInData = JSON.parse(localStorage.getItem(AppConfig.key.LoggedIn))
      const Role = LoggedInData?.role
      return Role === 'Branch'
    },
    userPrefix() {
      return this.isBranch ? (this.input.preUser || this.$store.state.userProfile.username) : ''
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      this.input = JSON.parse(JSON.stringify(this.data))
      this.validate = JSON.parse(JSON.stringify(defaultValidate))
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
    },
    showModal() {
      this.$refs['assistant-madal'].show()
    },
    hideModal() {
      this.$refs['assistant-madal'].hide()
    },
    handleSubmit() {
      console.log(JSON.stringify(this.input))
      if(this.input?._id) {
        this.update()
      }else{
        this.save()
      }
    },
    save() {
      if(this.input?.subUser && this.input?.password && this.input?.firstname) {
        this.input.preUser = this.userPrefix
        this.input.username = `${this.userPrefix}${this.input.subUser}`
        AssistantService.save(this.input)
        .then((response)=>{
          console.log(response)
          if(response.status === 'success') {
            this.needReload = true
            this.hideModal()
            this.$notify({
              type: 'success',
              title: 'สำเร็จ!',
              text: 'บันทึกเสร็จเรียบร้อย',
            })
          }
        })
        .catch((e)=>{
          if(e?.errors) {
            this.validate = JSON.parse(JSON.stringify(defaultValidate))
            const errors = Object.keys(e.errors).reduce((errors, key) => {
              errors[key] = {
                valid: false,
                invalidText: e.errors[key]
              }
              return errors
            }, {})

            this.validate = {
              ...this.validate,
              ...errors
            }
          }

          Swal.fire({
            title: e?.name || 'ผิดพลาด!',
            text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
      }
    },
    update() {
      this.input.preUser = this.userPrefix
      this.input.username = `${this.userPrefix}${this.input.subUser}`
      AssistantService.update(this.input._id, this.input)
        .then((response)=>{
          console.log(response)
          if(response.status === 'success') {
            this.needReload = true
            this.hideModal()
            this.$notify({
              type: 'success',
              title: 'สำเร็จ!',
              text: 'บันทึกเสร็จเรียบร้อย',
            })
          }
        })
        .catch((e)=>{
          if(e?.errors) {
            this.validate = JSON.parse(JSON.stringify(defaultValidate))
            const errors = Object.keys(e.errors).reduce((errors, key) => {
              errors[key] = {
                valid: false,
                invalidText: e.errors[key]
              }
              return errors
            }, {})

            this.validate = {
              ...this.validate,
              ...errors
            }
          }

          Swal.fire({
            title: e?.name || 'ผิดพลาด!',
            text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss">
#assistant-madal {
  input[type=text] {
    color: blue;
  }
}
</style>
