<template>
  <div class="container">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <strong>ผู้ช่วย</strong>
      <button class="btn btn-sm btn-link" @click="addAssistant">เพิ่มผู้ช่วย</button>
    </div>
    <div class="card">
      <table class="table table-group table-sm table-hover mb-0">
        <thead>
          <tr>
            <th width="25%">ชื่อ</th>
            <th width="25%">รหัสผู้ใช้</th>
            <th width="25%">ใช้งานล่าสุด</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items">
            <td style="vertical-align: middle;">{{ item.firstname }}</td>
            <td style="vertical-align: middle;">{{ item.username }}</td>
            <td style="vertical-align: middle;">
              <span>{{ dateFormat(item.last_active, 'DD/MM/YYYY HH:mm:ss') }}</span>
            </td>
            <td class="text-right">
              <button class="btn btn-sm btn-link" @click="editAssistant(item)">แก้ไข</button>
            </td>
          </tr>
          <tr v-if="items.length == 0">
            <td colspan="4" class="small text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
      </table>
    </div>

    <AssistantModal title="ตั้งค่าพนักงาน" :data="data" :is-show="isShowAssistantModal" @close="modalClosed" />
  </div>
</template>
<script>
import moment from '@/helpers/moment'

import AssistantService from '@/services/AssistantService.js'
import AssistantModal from './components/AssistantModal'

const defaultData = {
  _id: null,
  role: 'Assistant',
  preUser: '',
  subUser: '',
  username: '',
  password: '',
  firstname: '',
  lastname: '',
  tel: '',
  line_id: ''
}

export default {
  name: 'AdminSettingAssistant',
  components: {
    AssistantModal
  },
  data() {
    return {
      isShowAssistantModal: false,
      data: JSON.parse(JSON.stringify(defaultData)),
      Assistants: []
    }
  },
  computed: {
    items() {
      return this.Assistants
    }
  },
  methods: {
    loadAssistant() {
      AssistantService.gets('Assistant').then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.Assistants = response.data
        }
      })
    },
    addAssistant() {
      this.data = JSON.parse(JSON.stringify(defaultData))
      this.isShowAssistantModal = true
    },
    editAssistant(item) {
      console.log(item)
      let data = JSON.parse(JSON.stringify(defaultData))
      this.data = {
        ...data,
        _id: item._id,
        username: item.username,
        preUser: item.preUser,
        subUser: item.subUser,
        password: '',
        firstname: item.firstname,
        lastname: item.lastname,
        tel: item.tel,
        line_id: item.line_id
      }
      this.isShowAssistantModal = true
    },
    modalClosed(needReload) {
      this.isShowAssistantModal = false
      console.log('needReload', needReload)
      if(needReload) {
        this.loadAssistant()
      }
    },
    dateFormat(date, format='YYYY-MM-DD') {
      if(date) {
        return moment(date).format(format)
      }else{
        return '-'
      }
    }
  },
  mounted() {
    this.loadAssistant()
  }
}
</script>
